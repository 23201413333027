<template>
  <v-dialog v-model="esa_document" width="800px">
    <v-card>
      <v-toolbar dense class="gradient-bg elevation-0 white--text"
        ><v-toolbar-title>Early Salary Access Document</v-toolbar-title
        ><v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-carousel
        v-model="model"
        height="auto"
        hide-delimiters
        :show-arrows="false"
        v-if="images.length == 1"
      >
        <v-carousel-item
          v-for="images1 in images"
          :key="images1"
          :src="images1"
        >
        </v-carousel-item>
      </v-carousel>
      <v-carousel v-model="model" height="auto" hide-delimiters v-else>
        <v-carousel-item
          v-for="images1 in images"
          :key="images1"
          :src="images1"
        >
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    esa_document: Boolean,
    approvaldta: Object,
  },
  data: () => ({
    model: 0,
    images: "",
  }),
  watch: {
    esa_document: {
      handler() {
        this.images = this.approvaldta.images_keys;
        // console.log(this.images, "sd");
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
      // console.log(this.approvaldta);
    },
  },
};
</script>

<style>
</style>