<template>
  <div>
    <v-dialog v-model="approvaldailog" persistent width="450px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Early Salary Access Approval</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-4">
            <span> Approve or Reject Early Salary Access?<br /></span>
          </div>
          <v-select
            dense
            outlined
            label="Amount Type"
            v-model="amount_Type"
            :items="amount_source"
            class="mt-4"
            style="max-width: 220px"
          >
          </v-select>
          <v-select
            v-model="max_ammount"
            dense
            outlined
            type="number"
            :items="maxamount"
            label="Amount Approved"
            prepend-inner-icon="mdi-currency-inr"
            style="max-width: 220px"
            class="mt-1"
          >
          </v-select>
          <v-form ref="form">
            <div v-if="amount_Type == 'CHEQUE' || amount_Type == 'BANK'">
              <v-text-field
                style="max-width: 220px"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Required',
                  (v) =>
                    v.length <= 30 ||
                    'Transaction ID must be under 30 characters',
                ]"
                label="Transaction ID"
                v-model="transaction_id"
              >
              </v-text-field>

              <v-file-input
                prepend-icon=""
                small-chips
                multiple
                outlined
                dense
                style="max-width: 220px"
                label="Upload Documents"
                accept="image/*"
                v-model="documentFiles"
                @change="get_files(documentFiles)"
              ></v-file-input>
            </div>
          </v-form>
          <v-form ref="form1">
            <v-textarea
              v-model="comments"
              outlined
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  v.length <= 100 || 'Comments  must be under 100 characters',
              ]"
              label="Comments"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n8 pa-6">
          <v-spacer></v-spacer>
          <v-btn
            :loading="deleteLoading"
            color="red darken-1"
            class="white--text"
            small
            @click="validate_reject"
            >Reject</v-btn
          >
          <v-btn
            color="primary"
            class="white--text"
            small
            @click="validate_approve"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="confirm" persistent width="550px">
        <v-card class="overflow-hidden">
          <v-toolbar dense class="gradient-bg elevation-0 white--text">
            <v-toolbar-title>Early Salary Access Confirmation</v-toolbar-title
            ><v-spacer />
            <v-icon @click="close_dialog1()" class="white--text mr-n3"
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-card-text v-if="approveconfirm == true">
            <div class="mt-2">
              <span class="font-weight-medium"
                >Could you please confirm that you want to approve early salary
                access?</span
              >
            </div>
            <div align="center">
              <v-btn
                color="primary"
                class="white--text mt-5"
                small
                @click="checkconfirm_approval()"
                >Yes</v-btn
              >
              <v-btn
                color="primary"
                class="white--text mt-5 ml-2"
                small
                @click="close_dialog1()"
                >No</v-btn
              >
            </div>
          </v-card-text>
          <v-card-text v-if="rejectconfirm == true">
            <div class="mt-2">
              <span class="font-weight-medium"
                >Could you please confirm that you want to reject early salary
                access?</span
              >
            </div>
            <div align="center">
              <v-btn
                color="primary"
                class="white--text mt-5"
                small
                @click="checkconfirm_rejection()"
                >Yes</v-btn
              >
              <v-btn
                color="primary"
                class="white--text mt-5 ml-2"
                small
                @click="close_dialog1()"
                >No</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>
  
  <script>
/* eslint-disable */
import { API, graphqlOperation } from "aws-amplify";
import { approve_or_reject_flow } from "@/graphql/mutations.js";
var AWS = require("aws-sdk");
export default {
  props: {
    approvaldailog: Boolean,
    approvaldta: Object,
  },
  data: () => ({
    deleteLoading: false,
    amount_source: ["CASH", "CHEQUE", "BANK"],
    amount_Type: "CASH",
    max_ammount: 2000,
    maxamount: [2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
    comments: "",
    transaction_id: "",
    files: [],
    url: null,
    approveconfirm: false,
    rejectconfirm: false,
    confirm: false,
    documentFiles: [],
  }),
  watch: {
    approvaldailog: {
      handler() {
        if (this.approvaldailog == true) {
          this.max_ammount = this.approvaldta.ammount_requested;

          if (this.approvaldta.ammount_requested == 2000) {
            this.maxamount = [500, 1000, 1500, 2000];
          } else if (
            this.approvaldta.ammount_requested >= 2001 &&
            this.approvaldta.ammount_requested <= 2500
          ) {
            this.maxamount = [500, 1000, 1500, 2000, 2500];
          } else if (
            this.approvaldta.ammount_requested >= 2501 &&
            this.approvaldta.ammount_requested <= 3000
          ) {
            this.maxamount = [500, 1000, 1500, 2000, 2500, 3000];
          } else if (
            this.approvaldta.ammount_requested >= 3001 &&
            this.approvaldta.ammount_requested <= 3500
          ) {
            this.maxamount = [500, 1000, 1500, 2000, 2500, 3000, 3500];
          } else if (
            this.approvaldta.ammount_requested >= 3501 &&
            this.approvaldta.ammount_requested <= 4000
          ) {
            this.maxamount = [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000];
          } else if (
            this.approvaldta.ammount_requested >= 4001 &&
            this.approvaldta.ammount_requested <= 4500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 4501 &&
            this.approvaldta.ammount_requested <= 5000
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 5001 &&
            this.approvaldta.ammount_requested <= 5500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 5501 &&
            this.approvaldta.ammount_requested <= 6000
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 6001 &&
            this.approvaldta.ammount_requested <= 6500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 6501 &&
            this.approvaldta.ammount_requested <= 7000
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 7001 &&
            this.approvaldta.ammount_requested <= 7500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 7501 &&
            this.approvaldta.ammount_requested <= 8000
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500, 8000,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 8001 &&
            this.approvaldta.ammount_requested <= 8500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500, 8000, 8500,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 8501 &&
            this.approvaldta.ammount_requested <= 9000
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500, 8000, 8500, 9000,
            ];
          } else if (
            this.approvaldta.ammount_requested >= 9000 &&
            this.approvaldta.ammount_requested <= 9500
          ) {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500,
            ];
          } else {
            this.maxamount = [
              500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500,
              6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000,
            ];
          }
        }
      },
    },
    immediate: true,
  },

  methods: {
    checkconfirm_approval() {
      this.approveconfirm = false;
      this.confirm = false;
      this.approvalConfirm1();
    },
    checkconfirm_rejection() {
      this.rejectconfirm = false;
      this.confirm = false;
      this.rejectConfirm();
    },
    close_dialog1() {
      this.confirm = false;
      this.rejectconfirm = false;
      this.approveconfirm = false;
    },
    validate_approve() {
      if (this.$refs.form.validate() && this.$refs.form1.validate()) {
        // console.log(this.files), console.log(this.urls, "uuuuu");
        this.confirm = true;
        this.approveconfirm = true;
        // this.approvalConfirm1();
      } else {
        this.$emit("clicked", 3);
      }
    },
    validate_reject() {
      if (this.$refs.form1.validate()) {
        this.confirm = true;
        this.rejectconfirm = true;
        // this.rejectConfirm();
      } else {
        this.$emit("clicked", 4);
      }
    },
    close_dialog() {
      this.$emit("clicked", 0);
      // console.log(this.files, "11111111");
      // console.log(this.url, "urll1111");
      // this.value = "";
      this.files = "";
      // this.url = null;
      // console.log(this.files, "222222222");
      // console.log(this.url, "urll22222");
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();

      // console.log(this.approvaldta.ammount_requested);
    },
    async rejectConfirm() {
      try {
        let result = await API.graphql(
          graphqlOperation(approve_or_reject_flow, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              transaction_id: this.approvaldta.transaction_id,
              user_id: this.approvaldta.user_id,
              approve_status: "REJECTED",
              ammount_requested: this.max_ammount,
              approve_id: this.$store.getters.get_org_user_details[0].user_id,
              ammount_type: this.amount_Type,
              payment_id: undefined,
              comments: this.comments,
              images_keys: this.urls,
            },
          })
        );

        // console.log(JSON.parse(result.data.approve_or_reject_flow).Status);
        if (
          JSON.parse(result.data.approve_or_reject_flow).Status == "SUCCESS"
        ) {
          this.$emit(
            "successMsg",
            JSON.parse(result.data.approve_or_reject_flow).Message
          );
        } else {
          this.$emit(
            "errorMsg",
            JSON.parse(result.data.approve_or_reject_flow).Message
          );
        }
        // this.$emit("clicked", 2);
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$refs.form1.reset();
        this.$refs.form1.resetValidation();
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
      }
    },
    async approvalConfirm1() {
      try {
        let result = await API.graphql(
          graphqlOperation(approve_or_reject_flow, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              transaction_id: this.approvaldta.transaction_id,
              user_id: this.approvaldta.user_id,
              approve_status: "APPROVED",
              ammount_requested: this.max_ammount,
              approve_id: this.$store.getters.get_org_user_details[0].user_id,
              ammount_type: this.amount_Type,
              payment_id:
                this.amount_Type == "CASH" ? undefined : this.transaction_id,
              comments: this.comments,
              images_keys: this.urls,
            },
          })
        );
        // console.log(result,'result');
        // console.log(result.data.approve_or_reject_flow,'resusss');
        // console.log(JSON.parse(result.data.approve_or_reject_flow).Message,'resussss');
        // this.$emit("clicked", 1);

        if (
          JSON.parse(result.data.approve_or_reject_flow).Status == "SUCCESS"
        ) {
          this.$emit(
            "successMsg",
            JSON.parse(result.data.approve_or_reject_flow).Message
          );
        } else {
          this.$emit(
            "errorMsg",
            JSON.parse(result.data.approve_or_reject_flow).Message
          );
        }

        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$refs.form1.reset();
        this.$refs.form1.resetValidation();
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
      }
    },

    get_files(files) {
      // console.log(files);
      this.uploadedFiles = files;
      // console.log(this.uploadedFiles, "file array");
      if (this.uploadedFiles.length != 0) {
        this.loading = true;
        var self = this;
        this.urls = [];
        for (var i = 0; i < this.uploadedFiles.length; i++) {
          self.upload_S3(this.uploadedFiles[i]);
          // console.log(this.uploadedFiles[i], "fgh");
        }
      } else {
        return null;
      }
    },
    async upload_S3(files) {
      // console.log(files, "filesss");
      var imageUrl = [];
      var newUrl = [];
      var baseData = [];
      var file = files;
      // console.log("fr.readAsDataURL(file);", file);
      const fr = new FileReader();
      fr.readAsDataURL(file);
      // console.log("fr.readAsDataURL(file);", file);
      fr.addEventListener("load", () => {
        imageUrl = fr.result;
        // console.log(fr.result, "result");
        // console.log(imageUrl, "result");
        newUrl = imageUrl;
        newUrl = imageUrl.replace("data:" + file.type + ";base64,", "");
        // console.log(newUrl);
        baseData.push(newUrl);
        // console.log(baseData, "baseData");
        this.upload_S3_bucket(baseData[0], file);
      });
    },
    async upload_S3_bucket(baseData) {
      var self = this;

      var buf = Buffer.from(baseData, "base64");
      var array = new TextDecoder("utf-8").decode(uint8array);
      var asseceskey = JSON.parse(array);
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: asseceskey.access_key,
        secretAccessKey: asseceskey.secret_key,
      });

      var bucketName = "przncemembersprofile";

      var params = {
        Bucket: bucketName,
        Key: `workfield/${
          this.$store.getters.get_org_details[0].organisation_id
        }/${Date.now()}.jpg`,
        ACL: "public-read-write",
        Body: buf,
      };

      await s3Bucket.putObject(params, function (err) {
        if (err) {
          console.log(err);
          this.loading1 = false;
        } else {
          self.urls.push(
            `https://przncemembersprofile.s3.ap-south-1.amazonaws.com/${params.Key}`
          );
        }
      });
    },
  },
};
</script>
  
  <style>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
  