<template>
  <div>
    <v-card flat class="mt-2">
      <v-toolbar flat>
        <!-- <strong><h2>Early Salary Access Transaction</h2></strong> -->
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date1"
              label="Select a Month"
              readonly
              :disabled="isLoadingesa == true"
              dense
              v-bind="attrs"
              style="max-width: 200px"
              class="mt-8"
              outlined
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="minmonth"
            :max="new Date().toISOString().substr(0, 10)"
            v-model="date"
            type="month"
            no-title
            color="primary white--text"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(date), list_trans_details_of_user_or_org()
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-select
          dense
          style="max-width: 150px"
          :items="esa_status_item"
          outlined
          class="ml-2 mt-8"
          :disabled="isLoadingesa == true"
          v-model="esa_status"
          item-text="text"
          label="Status"
          item-value="value"
          @change="list_trans_details_of_user_or_org()"
        />
        <v-spacer />
        <v-btn
          @click="esa_report()"
          small
          :loading="loadingreport"
          class="mt-2"
          color="primary white--text"
          >Generate Report</v-btn
        >
      </v-toolbar>

      <v-card-title></v-card-title>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :headers="tabledata"
          :items="exporttable"
          :items-per-page="10"
          :fixed-header="fixed"
          :loading="isLoadingesa"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Data available.
            </v-alert>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <span>{{ item.user_name }}</span>
          </template>
          <template v-slot:[`item.approved_on`]="{ item }">
            <span v-if="item.approved_on == null">-</span>
            <span
              v-else
              class="overflow"
              v-text="get_date(item.approved_on)"
            ></span>
          </template>
          <template v-slot:[`item.dynamo_month`]="{ item }">
            <span>{{
              [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ][+item.dynamo_month.split("-")[1] - 1] +
              " " +
              item.dynamo_month.split("-")[0]
            }}</span>
          </template>
          <template v-slot:[`item.transaction_created`]="{ item }">
            <span>{{ item.transaction_created_on }}</span>
          </template>
          <template v-slot:[`item.images_keys`]="{ item }">
            <span v-if="item.images_keys != null">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click="view_profile(item)"
                    max-height="40"
                    color="primary"
                    >mdi-file-document</v-icon
                  >
                </template>
                <span class="white--text">Document</span>
              </v-tooltip>
            </span>
            <span v-if="item.images_keys == null">-</span>
          </template>
          <template v-slot:[`item.Approved`]="{ item }">
            <span>{{ item.Approved }}</span>
          </template>
          <template v-slot:[`item.Approved_By`]="{ item }">
            <span>{{ item.user_name }}</span>
          </template>
          <template v-slot:[`item.approved_amount`]="{ item }">
            <span v-if="item.approved_amount == null">-</span>
            <span>{{ item.approved_amount }} </span>
          </template>
          <!-- <template v-slot:[`item.requested_hours`]="{ item }">
            <span>{{
              Math.floor(Math.floor(item.requested_hours / 60) / 60) +
              "h " +
              (Math.floor(item.requested_hours / 60) % 60) +
              "m"
            }}</span>
          </template> -->
          <template v-slot:[`item.transaction_created_on`]="{ item }">
            <span
              class="overflow"
              v-text="get_date(item.transaction_created_on)"
            ></span>
          </template>
          <template v-slot:[`item.approve_status`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: green"
                  medium
                  v-show="item.approve_status == 'APPROVED'"
                >
                  mdi-account-check
                </v-icon>
              </template>
              <span class="white--text">APPROVED</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: orange"
                  @click="Approve(item)"
                  mediumapproved_on
                  v-show="item.approve_status == 'PENDING'"
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span class="white--text">PENDING </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.approve_status == 'REJECTED'"
                >
                  mdi-account-cancel
                </v-icon>
              </template>
              <span class="white--text">REJECTED </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.approve_status == 'CANCELED'"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span class="white--text">CANCELED</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <EsaApproval
      :approvaldailog="approvaldailog"
      :approvaldta="approvaldta"
      @clicked="close_prop"
      v-on:errorMsg="errorPop"
      v-on:successMsg="successPop"
    />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <div v-if="componentcheck == 1">
      <EsAfile
        :esa_document="esa_document"
        :approvaldta="approvaldta"
        @clicked="close_prop"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import EsaApproval from "../../components/Dialogs/EsaApproval.vue";
import EsAfile from "@/components/Dialogs/EsAfile.vue";
import { API, graphqlOperation } from "aws-amplify";
import { early_salary_reports } from "@/graphql/mutations.js";
import { list_trans_details_of_user_or_org } from "@/graphql/queries.js";
export default {
  components: {
    EsaApproval,
    SnackBar,
    Overlay,
    EsAfile,
  },
  data: () => ({
    approve_status: true,
    componentcheck: 0,
    esa_status: "ALL",
    esa_status_item: [
      { text: "All", value: "ALL" },
      { text: "Approved", value: "APPROVED" },
      { text: "Rejected", value: "REJECTED" },
      { text: "Pending", value: "PENDING" },
    ],
    date1:
      [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][Number(new Date().toISOString().split("T")[0].split("-")[1]) - 1] +
      " " +
      new Date().toISOString().split("T")[0].split("-")[0],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    isLoadingesa: false,
    height: 0,
    tabledata: [
      { text: "Members", fixed: true, value: "user_name" },
      { text: "Request", fixed: true, value: "ammount_requested" },

      // { text: "Month", fixed: true, value: "dynamo_month" },

      // { text: "Requested Hours", fixed: true, value: "requested_hours" },
      {
        text: "Pending On",
        fixed: true,
        value: "pending_on",
      },
      {
        text: "Requested On",
        fixed: true,
        value: "transaction_created_on",
      },

      {
        text: "Approved ",
        fixed: true,
        value: "approved_amount",
      },
      {
        text: "Approved On",
        fixed: true,
        value: "approved_on",
      },

      { text: "Status", fixed: true, value: "approve_status", sortable: false },
      {
        text: "Documents",
        fixed: true,
        value: "images_keys",
        sortable: false,
      },
    ],
    exporttable: [],
    SnackBarComponent: {},
    approvaldta: {},
    fixed: true,
    approvaldailog: false,
    loadingreport: false,
    esa_document: false,
    overlay: false,
    minmonth: "",
    selectedDate: "",
  }),
  watch: {
    date(val) {
      this.date1 =
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][Number(val.split("-")[1]) - 1] +
        " " +
        val.split("-")[0];
    },
  },
  created() {
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    this.height = window.innerHeight - 210;
    this.list_trans_details_of_user_or_org();

    // console.log(this.$store.getters.get_org_user_details[0].user_id,'getters');
    // console.log(this.$store.getters.get_org_details,'get');
  },
  methods: {
    // to give css for one row in datatabel
    // :item-class="rowClass"
    // rowClass(item) {
    //   if (item.approve_status === "PENDING") {
    //     return item.approve_status === "PENDING" ? "primary white--text" : "";
    //   }
    // },
    // to give css for one row in datatabel
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    close_prop(val) {
      if (val == 0) {
        this.approvaldailog = false;
        this.esa_document = false;
      } else if (val == 1) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Early Salary Access Approved",
        };
        this.approvaldailog = false;
        this.list_trans_details_of_user_or_org();
      } else if (val == 2) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Early Salary Access Rejected",
        };
        this.approvaldailog = false;
        this.list_trans_details_of_user_or_org();
      } else if (val == 3) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter All The Fields",
        };
      } else if (val == 4) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter the comments section Before Rejection",
        };
      }
    },
    successPop(val) {
      this.approvaldailog = false;

      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: val,
      };
      this.list_trans_details_of_user_or_org();
    },
    errorPop(val) {
      this.approvaldailog = false;

      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: val,
      };
      this.list_trans_details_of_user_or_org();
    },
    Approve(item) {
      this.approvaldta = item;
      this.approvaldailog = true;
    },
    view_profile(item) {
      this.componentcheck = 1;
      this.approvaldta = item;
      this.esa_document = true;
    },
    // get_date(dates) {
    //   const date = new Date(dates * 1000);

    //   const year = date.getFullYear();
    //   const month = date.getMonth() + 1;
    //   const day = date.getDate();

    //   const formattedDate = `${day < 10 ? "0" + day : day}-${
    //     month < 10 ? "0" + month : month
    //   }-${year}`;
    //   return formattedDate;
    // },
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    // get_date(dates) {
    //   const date = new Date(dates*1000);
    //   const formattedDate = date.toLocaleDateString();

    //   return formattedDate;
    // },
    async esa_report() {
      this.loadingreport = true;
      try {
        let result = await API.graphql(
          graphqlOperation(early_salary_reports, {
            input: {
              dynamo_month: this.date,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              approve_status: this.esa_status,
            },
          })
        );
        var respnse = JSON.parse(result.data.early_salary_reports);
        // console.log(respnse);
        if (respnse.Status == "SUCCESS") {
          var report = respnse.data;
          window.open(report);
          this.loadingreport = false;
        }
        // console.log(result);
        this.loadingreport = false;
        // window.open(item);
      } catch (error) {
        console.log(error);
        this.loadingreport = false;
        this.overlay = false;
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async list_trans_details_of_user_or_org() {
      this.overlay = true;
      this.isLoadingesa = true;
      try {
        await API.graphql(
          graphqlOperation(list_trans_details_of_user_or_org, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "ORG",
              dynamo_month: this.date,
              approve_status: this.esa_status,
            },
          })
        ).then((res) => {
          // console.log(res.data);
          // console.log(res.data.list_trans_details_of_user_or_org.data);
          this.exporttable = res.data.list_trans_details_of_user_or_org.data;
          // console.log(
          //   res.data.list_trans_details_of_user_or_org.data.user_name
          // );
          //   this.SnackBarComponent = {
          //   SnackbarVmodel: true,
          //   SnackbarColor: "green",
          //   Top: true,
          //   SnackbarText:res.data.list_trans_details_of_user_or_org.Message,
          // };
        });
        this.isLoadingesa = false;
        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.overlay = false;
        this.isLoadingesa = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>

<style scoped>
</style>