var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-8",staticStyle:{"max-width":"200px"},attrs:{"label":"Select a Month","readonly":"","disabled":_vm.isLoadingesa == true,"dense":"","outlined":""},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.minmonth,"max":new Date().toISOString().substr(0, 10),"type":"month","no-title":"","color":"primary white--text","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.date), _vm.list_trans_details_of_user_or_org()}}},[_vm._v(" OK ")])],1)],1),_c('v-select',{staticClass:"ml-2 mt-8",staticStyle:{"max-width":"150px"},attrs:{"dense":"","items":_vm.esa_status_item,"outlined":"","disabled":_vm.isLoadingesa == true,"item-text":"text","label":"Status","item-value":"value"},on:{"change":function($event){return _vm.list_trans_details_of_user_or_org()}},model:{value:(_vm.esa_status),callback:function ($$v) {_vm.esa_status=$$v},expression:"esa_status"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","loading":_vm.loadingreport,"color":"primary white--text"},on:{"click":function($event){return _vm.esa_report()}}},[_vm._v("Generate Report")])],1),_c('v-card-title'),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n5",attrs:{"height":_vm.height,"dense":"","headers":_vm.tabledata,"items":_vm.exporttable,"items-per-page":10,"fixed-header":_vm.fixed,"loading":_vm.isLoadingesa,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Data available. ")])]},proxy:true},{key:"item.user_name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user_name))])]}},{key:"item.approved_on",fn:function(ref){
        var item = ref.item;
return [(item.approved_on == null)?_c('span',[_vm._v("-")]):_c('span',{staticClass:"overflow",domProps:{"textContent":_vm._s(_vm.get_date(item.approved_on))}})]}},{key:"item.dynamo_month",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s([ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ][+item.dynamo_month.split("-")[1] - 1] + " " + item.dynamo_month.split("-")[0]))])]}},{key:"item.transaction_created",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transaction_created_on))])]}},{key:"item.images_keys",fn:function(ref){
        var item = ref.item;
return [(item.images_keys != null)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"max-height":"40","color":"primary"},on:{"click":function($event){return _vm.view_profile(item)}}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Document")])])],1):_vm._e(),(item.images_keys == null)?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.Approved",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Approved))])]}},{key:"item.Approved_By",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user_name))])]}},{key:"item.approved_amount",fn:function(ref){
        var item = ref.item;
return [(item.approved_amount == null)?_c('span',[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.approved_amount)+" ")])]}},{key:"item.transaction_created_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"overflow",domProps:{"textContent":_vm._s(_vm.get_date(item.transaction_created_on))}})]}},{key:"item.approve_status",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approve_status == 'APPROVED'),expression:"item.approve_status == 'APPROVED'"}],staticStyle:{"color":"green"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("APPROVED")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approve_status == 'PENDING'),expression:"item.approve_status == 'PENDING'"}],staticStyle:{"color":"orange"},attrs:{"small":"","depressed":"","mediumapproved_on":""},on:{"click":function($event){return _vm.Approve(item)}}},on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("PENDING ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approve_status == 'REJECTED'),expression:"item.approve_status == 'REJECTED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("REJECTED ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approve_status == 'CANCELED'),expression:"item.approve_status == 'CANCELED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("CANCELED")])])]}}],null,true)})],1)],1),_c('EsaApproval',{attrs:{"approvaldailog":_vm.approvaldailog,"approvaldta":_vm.approvaldta},on:{"clicked":_vm.close_prop,"errorMsg":_vm.errorPop,"successMsg":_vm.successPop}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),(_vm.componentcheck == 1)?_c('div',[_c('EsAfile',{attrs:{"esa_document":_vm.esa_document,"approvaldta":_vm.approvaldta},on:{"clicked":_vm.close_prop}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }